body { margin: 0; padding: 0; background-color: #202020; }

.korppi,
.planet {
    position: absolute;
    border-radius: 5px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    will-change: transform;
  }
  
.korppi {
    min-width: 30ch;
    min-height: 30ch;
    width: 35ch;
    height: 35ch;
    background-image: url(./images/korppi.png);
  }
  
.planet {
    min-width: 30ch;
    min-height: 30ch;
    width: 40vw;
    height: 40vw;
    max-width: 100ch;
    max-height: 100ch;
    background-image: url(./images/planet.svg);
  }